.transactionDetailContainer {
    background: white;
    min-height: calc(100vh - 140px);
}

.transactionDetailHeader {
    padding: 0.5rem 0rem;
}

.transactionDetailContainer {
    padding: 0.5rem 0rem;
}