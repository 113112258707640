#webcam_image {
    position: fixed;
}
.camera-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.camera-actions .photo-button {
    width: 100px;
    height: 100px;
    bottom: 10%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    position: absolute;
}
.camera-actions .switch-camera {
    bottom: 10%;
    left: 60%;
    margin-top: -50px;
    position: absolute;
}

.camera-actions .photo-button .circle {
    position: absolute;
    top: 12%;
    left: 12%;
    bottom: 12%;
    right: 12%;
    border-radius: 100%;
    background-color: #ffffff;
    opacity: 0;
}

.camera-actions .photo-button .ring {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 100%;
    border: 0.5em solid #ffffff;
    opacity: 0.8;
}
.camera-actions .photo-button .circle,
.camera-actions .photo-button .ring {
    transition: all 0.25s;
}
.camera-actions .photo-button:hover .circle {
    opacity: 1;
}
.camera-actions .photo-button:active .ring {
    opacity: 1;
}
.camera-actions .photo-button:active .circle {
    opacity: 0.5;
}

.btnControlls {
    margin-top: 2px;
    background: #3d4c53;
    overflow: hidden;
    text-align: center;
    transition: 0.2s;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.secondaryButton {
    position: relative;
    width: 200px;
    height: 100px;
    margin-top: -98px;
    padding-top: 2px;

    left: -250px;
    transition: 0.3s;
}
.btnControlls .secondaryButton.retake {
    background: #ba2f2f;
}
.btnControlls .secondaryButton.save {
    background: #2fba4f;
}
.btnControlls .btnText {
    color: white;
    transition: 0.3s;
}
.btnText2 {
    margin-top: 63px;
    margin-right: -130px;
    color: #fff;
}
.btnControlls:hover .retake {
    left: -130px;
}
.btnControlls:hover .save {
    left: -130px;
}
.btnControlls:hover .btnText {
    margin-left: 65px;
}
.btnControlls:active {
    /*Clicked and held*/
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width: 600px) {
    .camera-actions .photo-button {
        width: 50px;
        height: 50px;
        left: 55%;
    }
    .camera-actions .switch-camera {
        bottom: 10%;
        left: 60%;
        margin-top: -50px;
        position: absolute;
    }

    .camera-actions .photo-button .circle {
        position: absolute;
        top: 12%;
        left: 12%;
        bottom: 12%;
        right: 12%;
        border-radius: 100%;
        background-color: #ffffff;
        opacity: 0;
    }

    .camera-actions .photo-button .ring {
        border: 0.3em solid #ffffff;
    }
    .preview {
        max-width: 100%;
        height: auto;
    }
}
