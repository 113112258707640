.text-messages {
    background: white;
    padding: 0.75rem 0.5rem 0.75rem 0.75rem;
    height: calc(100vh - 60px);
}
.text-messages-chat {
    background: white;
    height: calc(100vh - 120px);
    overflow: auto;
}
.text-messages-container {
    padding: 0.75rem 0rem;
}

#textMessagePage .msgButton {
    margin-bottom: 13px;
}

#textMessagePage .msgButton.wide {
    min-width: 10rem;
    max-width: 10rem;
}

/* ========================= */

.textMessageTable {
    padding-top: 20px;
    height: calc(80vh - 100px);
}

.textMessageTable .hidden {
    display: none !important;
}

.textMessageTable th {
    background-color: #b9dee3;
}

.textMessageTable tr:nth-of-type(odd) {
    background-color: #fff;
}

.textMessageTable tr:nth-of-type(even) {
    background-color: #e8e8e8;
}

.textMessageTable tr:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.textMessageTable table.MuiTable-root .wideColumn {
    min-width: 350px;
    max-width: 450px;
}

.unRead {
    background: var(--grey-300);
    font-weight: 700;
    cursor: pointer;
}

.textMessageTable .MuiTableCell-root.error {
    color: #f44336;
    font-weight: 600;
}

.textMessageTable #denseSwitch {
    margin: -70px 0 0 20px;
}

.textMessageTable #denseSwitch .MuiTypography-body1 {
    font-size: 0.875rem;
}

/* ========================= */

.textMessageDialog .bubbleContainer {
    width: 100%;
    display: flex;
    padding: 0rem 0.7rem 0.1rem 0.7rem;
}

.textMessageDialog .bubbleContainer .bubble {
    border-radius: 8px;
    margin-top: 7px;
    padding: 10px;
    display: inline-block;
    box-shadow: 0px 0px 2px 0.5px;
}
.textMessageDialog .bubbleContainer.right .bubble .dateTitle {
    text-align: right;
    font-size: 0.7rem;
    color: var(--grey-600);
}
.textMessageDialog .bubbleContainer.left .bubble .dateTitle {
    text-align: right;
    font-size: 0.7rem;
    color: var(--grey-600);
}

.textMessageDialog .bubbleContainer.right {
    justify-content: flex-end !important;
}

.textMessageDialog .bubbleContainer.left {
    justify-content: flex-start !important;
}

.textMessageDialog .left .bubble {
    background-color: #dfe3e8;
}

.textMessageDialog .right .bubble {
    background-color: #05697e70;
}

.textMessageDialog .MuiDialogActions-root {
    margin-top: 2px;
}

.textMessageDialog .closeX {
    cursor: pointer;
    float: right;
    margin: -15px -15px 0 0;
}

/*-----------------------------------------*/

.textMessageSummary .title {
    font-weight: 500;
    color: #05697e;
}

.textMessageSummary .subtitle {
    font-weight: 600;
    color: #05697e;
}
