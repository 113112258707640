.user-admin {
    background: white;
    padding: 0.75rem 0.5rem 0.75rem 0.75rem;
    min-height: calc(100vh - 140px);
}
.user-admin-container {
    background: white;
    min-height: calc(100vh - 140px);
}

.user-container {
    padding: 0.75rem 1.5rem;
}
