.delivery .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;
}

.bold {
    font-weight: bold;
}

.report-table .Mui-even {
    background-color: #ffffff;
}
.report-table .Mui-odd {
    background-color: #fff;
}

.delivery-report.pickup {
    background-color: #fff44fb5;
}

@media only screen and (max-width: 600px) {
    .delivery .actions {
        grid-template-columns: "." ".";
    }
}
