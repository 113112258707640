.outline-badge-button {
    color: var(--grey-800);
    font-weight: 500;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    border: 0;
    background: 0;
    font-family: inherit;
    border-radius: var(--global-radius);
    padding:0.25rem;
    cursor: pointer;
}
.outline-badge-button:hover {
    background-color: var(--grey-300)
}