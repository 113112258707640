.reservationRowWrapper {
    display:grid;
    margin-bottom: 1rem;
    grid-template-areas: "eventName totalLabel"
                         "contact total"
                         "details details";
}

.reservationRowWrapper .contact {
    grid-area:contact;
}

.reservationRowWrapper .total {
    grid-area: total;
    text-align: end;
}

.reservationRowWrapper .details {
    grid-area: details;
}

.reservationRowWrapper .eventName {
    grid-area: eventName;
}
.reservationRowWrapper .totalLabel{
    grid-area: totalLabel;
    text-align: end;
}