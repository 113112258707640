.wrapper {
    height: 100vh;
    position: relative;
}
.innerWrapper {
    overflow: auto
}
.reservationSelect {
    background: var(--grey-100);
    padding: 0.5rem 1rem;
}

.tipSpacer {
    padding: 0 1rem;
}
.tipTableHeader {
    border-bottom: 1px solid var(--grey-300, #DFE3E8);
    padding: 0.5rem;
    display:flex;
    align-items: center;
}
.tipTableHeader.hidden {
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.tipTable {
    display: grid;
    grid-template-columns: repeat(4, max-content) 1fr;
    gap:0;
    margin-bottom: 2.5rem;
}
.tipTableCell {
    border-bottom: 1px solid var(--grey-300, #DFE3E8);
    padding: 0.5rem;
    display:flex;
    align-items: center;
}
@media only screen and (max-width: 550px) {
    .tipTable {
        grid-template-columns: repeat(4, min-content) 1fr;
    }
}