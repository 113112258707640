.cart-wrapper {
    background-color: white;
    position: relative;
}
.cart-footer {
    border-top: 1px solid var(--grey-300, #DFE3E8);
    background: var(--grey-100, #F9FAFB);
    width:100%;
    padding:1rem;
    display: flex;
    gap:0.25rem;
}

.cart-wrapper .cart-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.cart-wrapper .cart-item {
    border-top: 1px solid #bababa;
    padding: 8px 0;
}

.cart-wrapper .summary-container {
    padding-bottom: 6px;
}

.cart-totals-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    margin: 20px 40px 15px 0;
}

.cart-totals-column {
    display: grid;
    grid-template-columns: 120px 80px;
    column-gap: 10px;
}
.cart-totals-grid .money {
    text-align: right;
}
.cart-totals-grid .negative-value {
    color: #f44336;
    margin-left: -0.8ch;
}

.cart-totals-grid .label {
    font-weight: 700;
}

.tip-panel-container {
    max-width: 1280px;
    margin: auto;
    background-color: white;
    padding: 20px 10px 10px;
}

.tip-panel-container .tip-row {
    display: grid;
    grid-template-columns: 60px 8ch 12ch;
    grid-gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.tip-panel-container .custom-tip-row {
    display: grid;
    grid-template-columns: 60px 80px 150px 350px;
    grid-gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.tip-panel-container .MuiButton-outlined {
    padding: 7px 15px;
}

.tip-panel-container .MuiOutlinedInput-input {
    padding: 10px 15px 11px;
}

.tip-panel-container .label {
    font-weight: 700;
}

.tip-panel-container .price-label {
    color: #05697e;
    font-weight: 600;
}
