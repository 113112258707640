.settings-container {
    background: white;
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    min-height: calc(100vh - 140px);
}

.settings-header {
    padding: 0.75rem 1.5rem;
}

.user-container {
    padding: 0.75rem 1.5rem;
}

.user-section {
    padding: 0.5rem 0rem;
}
