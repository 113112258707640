:root {
    box-sizing: border-box;
    /*font-size:16px;*/
    --dark-aqua-bright: #C2F5FF;
    --dark-aqua-lighter: #4FDCF8;
    --dark-aqua-light: #08A5C4;
    --dark-aqua-main: #05697E;
    --dark-aqua-dark: #045060;
    --dark-aqua-darker: #033742;

    --deep-sapphire-bright: #c2d0ff;
    --deep-sapphire-lighter: #4d75f9;
    --deep-sapphire-light: #0a3feb;
    --deep-sapphire-main: #081e66;
    --deep-sapphire-dark: #04103a;
    --deep-sapphire-darker: #010a28;

    --squash-bright: #ffe7c2;
    --squash-lighter: #fed290;
    --squash-light: #fbb64b;
    --squash-main: #faa21b;
    --squash-dark: #ca7a02;
    --squash-darker: #7a4900;

    --info-lighter: #cafdf5;
    --info-light: #61f3f3;
    --info-main: #00b8d9;
    --info-dark: #006c9c;
    --info-darker: #003768;

    --success-lighter: #d3fcd2;
    --success-light: #77ed8d;
    --success-main: #22c55e;
    --success-dark: #118d57;
    --success-darker: #065e49;

    --warning-lighter: #fff5cc;
    --warning-light: #ffd666;
    --warning-main: #ffab00;
    --warning-dark: #b76e00;
    --warning-darker: #7a4100;

    --error-lighter: #fffe9d5;
    --error-light: #ffac82;
    --error-main: #ff5630;
    --error-dark: #b71d18;
    --error-darker: #7a0916;

    --grey-100: #f9fafb;
    --grey-200: #f4f6f8;
    --grey-300: #dfe3e8;
    --grey-400: #c4cdd5;
    --grey-500: #919eab;
    --grey-600: #637381;
    --grey-700: #454f5b;
    --grey-800: #212b36;
    --grey-900: #161c24;

    --waverunn-purple-200:#CE93D8;
    --global-radius: 0.5rem;
    --text-primary-light: var(--grey-900);
    --text-primary-dark: var(--grey-100);

    --text-secondary-light: var(--grey-600);
    --text-secondary-dark: var(--grey-500);

    --text-disabled-light: var(--grey-500);
    --text-disabled-dark: var(--grey-600);

    --unavailable_grey: #9E9E9E;
    --unavailable_orange: #FF5630;
    --open: #FFF;
    --sold_out: #3A59BB;
    --sold_out_resources: #8FC4DF;
}