.merchandise-table-container {
    overflow-x: auto;
    width: 100%;
}
.merchandise-table {
    border-collapse: collapse;
    line-height: 1.1rem;
}

.merchandise-table th {
    padding: 0.625rem 0.313rem;
    color: #919eab;
    font-size: 10px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
    align-items: center;
    vertical-align: middle;
}

.merchandise-table tr {
    vertical-align: text-top;
}

.merchandise-table td,
.merchandise-table th {
    padding: 10px 5px;
}
