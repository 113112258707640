.buttonGroup {
    display: inline-flex;
    align-items: center;
    /*gap: 0.25rem;*/
    border-radius: var(--global-radius, 0.5rem);
    background: var(--grey-100, #F9FAFB);
    padding: 0.25rem 0;
}
.buttonGroup .button {
    display:flex;
    align-items: center;
    gap:0.25rem;
    cursor:pointer;
    padding: 0.25rem;
    margin: 0 0.25rem;
    border-radius: var(--global-radius);
    color:var(--dark-aqua-main);
    transition: 0.3s background-color,color ease-in-out;
}
.buttonGroup .button:hover {
    background: var(--grey-300);
}
.buttonGroupText {
    color: var(--grey-900, #161C24);
    font-weight: 400;
}

.buttonGroup .button.disabled {
    color: var(--grey-300);
    cursor:default;
}
.buttonGroup .button.disabled:hover {
    background: var(--grey-100);
}
.buttonGroup .button.disabled .buttonGroupText {
    color: var(--grey-300);
}
.buttonGroup .button.disabled .buttonGroupText:hover {
    background: var(--grey-100);
}
.buttonGroupIcon {
    color:var(--dark-aqua-main);
}