.simple-calendar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.simple-calendar .calNav {
    display: flex;
    width: 100%;
    position: relative;
}
.simple-calendar .calendarMonth {
    flex: auto;
    align-self: center;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
.simple-calendar .calDayCell {
    width: 14.28%;
    min-height: 2em;
    padding: 0.3em;
    text-align: center;
}
.simple-calendar .past {
    color: var(--grey-300);
}
.simple-calendar .today {
    color: var(--sucess-lighter);
}
.simple-calendar .calDayButton {
    min-width: 2.3em;
    padding: 0.2em 0.4em;
    border-radius: 100px;
    border: 1px solid var(--dark-aqua-main);
    background: var(--dark-aqua-bright);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    color: var(--dark-aqua-main);
}

.simple-calendar .calDayButton.selectedDay {
    background-color: var(--dark-aqua-main);
    color: white;
    opacity: unset;
}
.simple-calendar .calDayButton .dotCurrentDay {
    position: absolute;
    bottom: -4px;
    background-color: var(--squash-main);
    border-radius: 8px;
    width: 8px;
    height: 8px;
}
