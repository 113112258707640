.reservation-data-container {
    display: grid;
    gap: 2px;
    grid-template-areas:
        "reservationId"
        "data-actions";
}
.reservationId {
    grid-area: reservationId;
}
.data-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    grid-area: data-actions;
}
.contact-info-readonly-phone-email {
    display: flex;
    flex-direction: row;
}
