.main-drawer .nav-item {
    color: var(--grey-600);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 0.25rem;
    transition: all 0.2s ease-in;
    text-decoration: none;
    cursor: pointer;
}

.main-drawer .nav-item:hover {
    background-color: var(--grey-300);
}
