.wrapper {
    height: 100vh;
    position: relative;
}
.innerWrapper {
    overflow: auto
}
.reservationSelect {
    background: var(--grey-100);
    padding: 0.5rem 1rem;
}
.wrapperFooter {
    border-top: 1px solid var(--grey-300, #DFE3E8);
    background: var(--grey-100, #F9FAFB);
    padding: 0.5rem;
    width: 100%;
}
.catalogEntryInputWrapper {
    display: flex;
    gap: 0.5rem;
}

.catalogEntryInputCount {
    border: 1px solid;
    border-color: var(--grey-300);
    border-radius: var(--global-radius);
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
}

.catalogEntryAddToCart {
    box-shadow: none;
    border: none;
    color:var(--dark-aqua-main);
    border-radius: var(--global-radius);
    background: var(--grey-200, #F4F6F8);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.3125rem */
    transition: background,color 0.2s ease-in-out;
    cursor: pointer;
    display:flex;
    gap:0.5rem;
    align-items: center;
}
.catalogEntryAddToCart:disabled {
   color:var(--grey-300);
    background: var(--grey-200);
    cursor:auto;
}

.catalogEntryAddToCart:hover:not([disabled]) {
    background: var(--grey-300);
}
.catalogEntryAddToCart:focus {
    outline:solid var(--grey-600);
}

.catalogEntryItemCode {
    color: var(--grey-900);
    border-radius: var(--global-radius);
    font-weight: 500;
}

.flexWrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.flexSpacer {
    flex-grow:1;
}
.catalogEntryWrapper {
    padding: 0.5rem 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--grey-300);
}

.catalogEntryIndicator {
    display: inline-flex;
    padding: 0.25rem 0.5rem;
    align-items: flex-start;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius:10rem;
}
.catalogEntryIndicator.warning {
    background: var(--warning-lighter);
    color:var(--warning-darker);
}
.catalogEntryIndicator.success {
    background: var(--success-lighter);
    color:var(--success-darker);
}